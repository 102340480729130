<template src="./index.html">
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import iframeObj from '../../util/iframe'
import NewPageBox from '_c/NewPageBox'
import { getAddressName } from '../../util/getAddressName'

export default {
  async asyncData ({ store, route }) {
    const DISPATCH = store.dispatch
    const PARAMS = store.state.route.params
    let result = await DISPATCH('dataPartner/getPartnerDetail', {id : parseInt(PARAMS.id)})
    const detail = result && result.listData.data || {}
    return await Promise.all([
      DISPATCH('dataPartner/getRecommendProviders', {
        Uin: detail.uin + '',
        SubAccountUin: detail.subAccountUin + '',
				Category1Id: detail.serviceType,
				Offset: 0,
        Limit: 3,
      }),
      DISPATCH('dataPartner/getTerminalList', {
        spUin: detail.uin + '',
        spSubAccountUin: detail.subAccountUin + '',
        offset: 0,
        limit: 12,
      }),
      DISPATCH('dataPartner/getDeviceList', {
        spUin: detail.uin + '',
        spSubAccountUin: detail.subAccountUin + '',
        offset: 0,
        limit: 12,
      }),
      DISPATCH('dataPartner/getSolutionList', {
        spUin: detail.uin + '',
        spSubAccountUin: detail.subAccountUin + '',
        offset: 0,
        limit: 12,
      })
    ])
  },
  components:{
    NewPageBox,
  },
  data() {
    return {
      currentTab: 1,
      partnerPageNo: 1,
    }
  },
  computed: {
     ...mapState('dataPartner',[
       'partnerDetail',
       'terminalList',
       'deviceList',
       'solutionList',
       'recommendProviders',
     ]),
     List() {
       switch (this.currentTab) {
         case 2:
          return this.terminalList
          break;
         case 3:
          return this.deviceList
           break;
         case 4:
           return this.solutionList
           break;
         default:
           return []
           break;
			 }
		 },
     pageSize() {
       const pagesize = this.List.total/12 + 1
       return parseInt(pagesize)
     }
	},
  methods: {
    ...mapActions('dataPartner', [
      'getTerminalList',
      'getDeviceList',
      'getSolutionList',
      'getRecommendProviders',
		]),
    getCityName(pid, cid){
			const city = getAddressName(pid, cid).city
      return city
		},
		go_detail(id) {
			window.location.href = `//iot.cloud.tencent.com/market/partnerdetail/${id}`
    },
    open_page(url) {
      window.open(url)
    },
    click_page(pageNum, pageName) {
      const offset = (pageNum-1)*10
      this.partnerPageNo = pageNum
      const params = {
        offset,
      }
      switch (this.currentTab) {
        case 2:
          this.get_terminalList(params)
          break;
        case 3:
          this.get_deviceList(params)
          break;
        case 4:
          this.get_solutionList(params)
          break;
        default:
          break;
      }
    },
    change_tab(value) {
      this.currentTab = value
    },
    get_terminalList(param) {
      const params = {
        spUin: this.partnerDetail.uin + '',
        spSubAccountUin: this.partnerDetail.subAccountUin + '',
        offset: 0,
        limit: 12,
        ...param
      }
      this.getTerminalList(params)
    },
    get_deviceList(param) {
      const params = {
        spUin: this.partnerDetail.uin + '',
        spSubAccountUin: this.partnerDetail.subAccountUin + '',
        offset: 0,
        limit: 12,
        ...param
      }
      this.getDeviceList(params)
    },
    get_solutionList(param) {
      const params = {
        spUin: this.partnerDetail.uin + '',
        spSubAccountUin: this.partnerDetail.subAccountUin + '',
        offset: 0,
        limit: 12,
        ...param
      }
      this.getSolutionList(params)
    },
  },
	mounted() {
		this.partnerDetail.productDomain && iframeObj.setIframeContent('iframebox', this.partnerDetail.productDomain)
	},
}
</script>
<style lang="less" scoped>
.market-contact-text-span:hover {
  color: #00a4ff
}
</style>